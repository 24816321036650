import React, { useEffect, useState } from "react";
import {
	Alert,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton, Link,
	Menu,
	MenuItem,
	Modal,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography
} from "@mui/material";
import CarIcon from "@mui/icons-material/DirectionsCar";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ApiCallStatus, PreferenceIds, ScreenSize } from "../../utils/constants";
import useSystemHook from "../../hooks/SystemHook";
import { useAuth0 } from "@auth0/auth0-react";
import "./style.css";

const modalStyles = {
	desktop: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "35%",
		bgcolor: "#FFFFFF",
		boxShadow: 24,
		p: 4,
		borderRadius: "8px",
	},
	tablet: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "70%",
		bgcolor: "#FFFFFF",
		boxShadow: 24,
		p: 3,
	},
	mobile: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "90%",
		bgcolor: "#FFFFFF",
		boxShadow: 24,
		p: 2,
	},
};

const SavedVehiclesModal = ({
	open,
	openChooseVehicleModal,
	setClose,
	currentUserVehicleId,
	userVehicles,
	upsertUserPreferenceAsync,
	removeUserVehicleAsync,
}) => {
	const { screenSize } = useSystemHook();
	const { getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0();
	const [selectedVehicleId, setSelectedVehicleId] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const menuOpen = Boolean(anchorEl);

	const handleMenuClick = (event, id) => {
		setAnchorEl(event.currentTarget);
		setSelectedVehicleId(id);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		setSelectedVehicleId(null);
	};

	const closeModal = () => setClose();

	const handleChangeVehicleClick = async () => {
		await upsertUserPreferenceAsync(PreferenceIds.AccountCurrentUserVehicle, selectedVehicleId);
		handleMenuClose();
	};

	const handleRemoveVehicleClick = async () => {
		await removeUserVehicleAsync(selectedVehicleId);
		handleMenuClose();
	};

	return (
		<Modal open={open} onClose={closeModal}>
			<Box sx={modalStyles[screenSize]}>
				<Grid container justifyContent="space-between" alignItems="center" sx={{ paddingBottom: 2 }}>
					<Typography variant="body2" fontWeight="bold">
						Your Garage
					</Typography>
					<IconButton onClick={closeModal}>
						<CloseIcon fontSize="small" />
					</IconButton>
				</Grid>

				{isAuthenticated ? (
					userVehicles.length > 0 ? (
						<TableContainer component={Paper}>
							<Table size="small">
								<TableBody>
									{userVehicles.map((vehicle) => (
										<TableRow key={vehicle.userVehicleId}>
											<TableCell>
												{currentUserVehicleId === vehicle.userVehicleId && (
													<CarIcon fontSize="small" sx={{ color: "#ec3323" }} />
												)}
											</TableCell>
											<TableCell>
												<Typography variant="caption" fontWeight="bold">
													{`${vehicle.make} ${vehicle.model} ${vehicle.variant} ${vehicle.year}`}
												</Typography>
											</TableCell>
											<TableCell align="right">
												{currentUserVehicleId !== vehicle.userVehicleId && (
													<>
														<IconButton
															aria-label="more"
															size="small"
															onClick={(event) => handleMenuClick(event, vehicle.userVehicleId)}
														>
															<MoreVertIcon fontSize="small" />
														</IconButton>
														<Menu
															anchorEl={anchorEl}
															open={menuOpen}
															onClose={handleMenuClose}
															PaperProps={{ elevation: 3 }}
														>
															<MenuItem onClick={handleChangeVehicleClick}>
																<Typography variant="caption">Set as active</Typography>
															</MenuItem>
															<MenuItem onClick={handleRemoveVehicleClick}>
																<Typography variant="caption">Remove</Typography>
															</MenuItem>
														</Menu>
													</>
												)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					) : (
						<Grid container justifyContent="center" sx={{ mt: 2 }}>
							<Typography variant="caption">No vehicles saved</Typography>
						</Grid>
					)
				) : (
					<Grid container justifyContent="center" sx={{ mt: 2 }}>
						<Alert severity="warning">
							Please <Link to="/login">log in</Link> or <Link to="/register">create an account</Link> to access this feature.
						</Alert>
					</Grid>
				)}

				{isAuthenticated && (
					<Grid container justifyContent="center" sx={{ mt: 2 }}>
						<Button
							onClick={openChooseVehicleModal}
							variant="contained"
							color="error"
							size="small"
						>
							{userVehicles.length === 0 ? "Add a vehicle" : "Add another vehicle"}
						</Button>
					</Grid>
				)}

				{isLoading && (
					<Backdrop open={isLoading} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
						<CircularProgress color="inherit" />
					</Backdrop>
				)}
			</Box>
		</Modal>
	);
};

export default SavedVehiclesModal;

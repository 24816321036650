import React, { useEffect } from "react";
import { SouthAfricanRand } from "../../utils/helpers";
import PageHeader from "../PageHeader";
import useSystemHook from "../../hooks/SystemHook";
import { ScreenSize } from "../../utils/constants";
import {
	Alert,
	Avatar,
	Box,
	Button,
	Divider,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography
} from "@mui/material";

const PaymentAccountCredit = ({
	paymentOrderInfo,
	fullTradeAccount,
	gotoPaymentPage,
	onSubmit
}) => {
	const { screenSize } = useSystemHook();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const renderCreditInfoTable = (info) => (
		<Table size={screenSize === ScreenSize.Desktop ? "medium" : "small"}>
			<TableBody>
				<TableRow>
					<TableCell>
						<Typography variant="body2" fontWeight="bold">
							Amount Due
						</Typography>
					</TableCell>
					<TableCell>
						<Typography variant="body2">
							{SouthAfricanRand.format(info.amount)}
						</Typography>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<Typography variant="body2" fontWeight="bold">
							Available Credit
						</Typography>
					</TableCell>
					<TableCell>
						<Typography variant="caption">
							{SouthAfricanRand.format(fullTradeAccount.availableCredit)}
						</Typography>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<Typography variant="body2" fontWeight="bold">
							Credit Limit
						</Typography>
					</TableCell>
					<TableCell>
						<Typography variant="caption">
							{SouthAfricanRand.format(fullTradeAccount.creditLimit)}
						</Typography>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);

	const renderPaymentButton = (isCreditSufficient) => (
		<Box sx={{ textAlign: "center" }}>
			{isCreditSufficient ? (
				<Button
					variant="contained"
					color="error"
					size="small"
					onClick={onSubmit}
					sx={{ width: screenSize === ScreenSize.Desktop ? "50%" : "100%", mb: 2 }}
				>
					Pay
				</Button>
			) : (
				<>
					<Alert severity="warning" sx={{ mb: 2 }}>
						<Typography variant="body2" sx={{ fontWeight: "bold", mb: 1 }}>
							Not enough credit available
						</Typography>
						<Typography variant="caption">
							Your account does not have enough credit to complete this transaction. Please use a different payment method.
						</Typography>
					</Alert>
					<Button
						variant="contained"
						color="error"
						size="small"
						onClick={gotoPaymentPage}
						sx={{ width: screenSize === ScreenSize.Desktop ? "50%" : "100%" }}
					>
						Choose different payment method
					</Button>
				</>
			)}
		</Box>
	);

	return (
		<>
			<PageHeader title="Payment" />
			<Box
				sx={{
					minHeight: screenSize === ScreenSize.Desktop ? "100vh" : "50vh",
					backgroundColor: "#f7f7f7",
					padding: screenSize === ScreenSize.Desktop ? "2rem" : "1rem"
				}}
			>
				<Grid container justifyContent="center">
					<Grid item xs={12} md={8}>
						<Paper elevation={3} sx={{ padding: "2rem", textAlign: "center" }}>
							<Avatar
								src="https://argusweb.azureedge.net/argus-web/logo.svg"
								alt="Logo"
								sx={{
									width: 150,
									height: screenSize === ScreenSize.Desktop ? 80 : 60,
									margin: "0 auto 1.5rem"
								}}
							/>
							{!!fullTradeAccount && !!paymentOrderInfo && (
								<>
									<Grid container spacing={4} justifyContent="center">
										<Grid item xs={12} md={6}>
											{renderCreditInfoTable(paymentOrderInfo)}
										</Grid>
									</Grid>
									<Divider sx={{ my: 3 }} />
									{renderPaymentButton(
										fullTradeAccount.availableCredit >= paymentOrderInfo.amount
									)}
								</>
							)}
						</Paper>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default PaymentAccountCredit;

import React, { Fragment, useEffect, useState } from "react";
import { cloneDeep, remove } from "lodash";
import "./style.css";
import { useArgusCart } from "../../contexts/ArgusCartContext";
import { confirmationChoices } from "../ConfirmationModal";
import PageHeader from "../PageHeader";
import { Avatar, Box, Button, Grid, IconButton, InputAdornment, Link, Paper, Tooltip, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { SouthAfricanRand } from "../../utils/helpers";
import { CustomerCategory } from "../../utils/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import { CartSummary } from "../CartSummary";

const Cart = ({
	loading,
	customerCategory,
	pricingData,
	vat,
	cartNettTotal,
	settlementAmount,
	vatAmount,
	total,
	createCheckout,
	goBack
}) => {
	const {
		items,
		setItems,
		removeItem,
		emptyCart,
		clearCartMetadata,
		updateItemQuantity
	} = useArgusCart();
	const [clearCartModalOpen, setClearCartModalOpen] = useState(false);
	const [inputValues, setInputValues] = useState({});
	const [editQuantity, setEditQuantity] = useState({});
	const [errorMessages, setErrorMessages] = useState({});

	const scrollToTop = () => {
		window.scroll(0, 0);
	};

	useEffect(() => {
		scrollToTop();
	}, []);

	useEffect(() => {
		const initialValues = items.reduce((acc, item) => {
			acc[item.id] = item.quantity.toString();
			return acc;
		}, {});
		setInputValues(initialValues);
	}, [items]);


	const clearCart = () => {
		emptyCart();
		clearCartMetadata();
		setClearCartModalOpen(false);
	};

	const handleClearCartModalOpen = () => setClearCartModalOpen(true);

	const closeModalHandle = () => {
		setClearCartModalOpen(false);
	};

	const handleClearCartModalClose = (confirmationChoice) => {
		const shouldClear = confirmationChoices.confirm === confirmationChoice;

		if (!!shouldClear) {
			clearCart();
		}

		closeModalHandle();
	};

	const onRemoveItemClick = (sku) => {
		const cartLibItemsCopy = cloneDeep(items);
		remove(cartLibItemsCopy, (x) => x.sku === sku);
		removeItem(sku);
		setItems(cartLibItemsCopy);
	};

	const handleCheckoutClick = async () => {
		await createCheckout();
	};

	const goToCheckout = async () => {
		await handleCheckoutClick();
	};

	const handleQuantityChange = (id, sku, value) => {
		const quantity = parseInt(value);

		if (isNaN(quantity) || quantity <= 0) {
			setErrorMessages((prev) => ({
				...prev,
				[sku]: 'Quantity must be a positive number.',
			}));
		} else if (quantity > 500) {
			setErrorMessages((prev) => ({
				...prev,
				[sku]: 'For quantities greater than 500, please contact our sales team.',
			}));
		} else {
			setErrorMessages((prev) => ({
				...prev,
				[sku]: '',
			}));
			updateItemQuantity(id, quantity);
		}
	};

	const handleIncrement = (id, sku, currentQuantity) => {
		if (currentQuantity < 500) {
			updateItemQuantity(id, currentQuantity + 1);
			setErrorMessages((prev) => ({
				...prev,
				[sku]: '',
			}));
		}
	};

	const handleDecrement = (id, sku, currentQuantity) => {
		if (currentQuantity > 1) {
			updateItemQuantity(id, currentQuantity - 1);
			setErrorMessages((prev) => ({
				...prev,
				[sku]: '',
			}));
		}
	};

	return (
		<Fragment>
			<PageHeader title="Cart" />
			<>
				{loading === false &&
					<Box sx={{ padding: '1rem', minHeight: '60vh' }}>
						{!!items && !!pricingData && items.length > 0 ? (
							<Grid container spacing={2} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
								<Grid item xs={12} md={9}>
									{items.map((item, index) => {
										const productLink = `/product/${item.sku}`;
										const itemPricingData = pricingData.find((p) => p.sku === item.sku);

										return (
											<Paper
												elevation={4}
												sx={{
													padding: '1rem',
													marginBottom: '1.5rem',
													borderRadius: 2,
													width: '100%'
												}}
												key={item.sku}
											>
												<Grid container alignItems="center" spacing={2}>
													<Grid item xs={12} sm={4} md={2} textAlign="center">
														<Link href={productLink}>
															<Avatar
																src={item.image}
																alt={item.name}
																variant="square"
																sx={{ width: { xs: 60, md: 80 }, height: { xs: 60, md: 80 }, margin: '0 auto' }}
															/>
														</Link>
													</Grid>
													<Grid item xs={12} sm={8} md={4}>
														<Link href={productLink} underline="hover">
															<Typography variant="body2" fontWeight="bold" color="textPrimary">
																{item.name}
															</Typography>
														</Link>
														<Typography variant="caption" color="textSecondary">
															SKU: {item.sku}
														</Typography>
													</Grid>
													<Grid item xs={6} sm={4} md={2} textAlign="center">
														<Typography variant="body2" color="textSecondary" sx={{ marginBottom: '0.5rem' }}>
															Qty
														</Typography>
														<TextField
															variant="outlined"
															type="number"
															value={item.quantity}
															size="small"
															onChange={(e) => handleQuantityChange(item.id, item.sku, e.target.value)}
															inputProps={{
																min: 1,
																max: 500,
																step: 1,
																'aria-label': 'quantity',
																style: { textAlign: 'center', fontSize: '0.875rem' },
															}}
															error={!!errorMessages[item.sku]}
															helperText={errorMessages[item.sku]}
															InputProps={{
																startAdornment: (
																	<InputAdornment position="start">
																		<IconButton
																			onClick={() => handleDecrement(item.id, item.sku, item.quantity)}
																			disabled={item.quantity <= 1}
																			sx={{ padding: 0 }}
																		>
																			<RemoveIcon fontSize="small" />
																		</IconButton>
																	</InputAdornment>
																),
																endAdornment: (
																	<InputAdornment position="end">
																		<IconButton
																			onClick={() => handleIncrement(item.id, item.sku, item.quantity)}
																			disabled={item.quantity >= 500}
																			sx={{ padding: 0 }}
																		>
																			<AddIcon fontSize="small" />
																		</IconButton>
																	</InputAdornment>
																),
															}}
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={3} textAlign="right">
														<Typography variant="body2" fontWeight="bold">
															{SouthAfricanRand.format(itemPricingData.price * item.quantity)}
														</Typography>
														{itemPricingData.invoicePrice > 0 && itemPricingData.invoicePrice !== itemPricingData.price && (
															<Typography component="p" variant="caption">
																Invoice: {SouthAfricanRand.format(itemPricingData.invoicePrice * item.quantity)}
															</Typography>
														)}
														{customerCategory !== CustomerCategory.Retail && (
															<Typography component="p" variant="caption">
																Ex. VAT
															</Typography>
														)}
													</Grid>
													<Grid item xs={12} sm={4} md={1} textAlign="center">
														<Tooltip title="Remove item" arrow>
															<IconButton
																color="error"
																onClick={() => onRemoveItemClick(item.sku)}
															>
																<DeleteIcon fontSize="small" />
															</IconButton>
														</Tooltip>
													</Grid>
												</Grid>
											</Paper>
										);
									})}
								</Grid>
								<Grid item xs={12} md={3}>
									<CartSummary
										total={total}
										cartNettTotal={cartNettTotal}
										settlementAmount={settlementAmount}
										vat={vat}
										vatAmount={vatAmount}
										buttonText="Checkout"
										onButtonClick={goToCheckout}
									/>
								</Grid>
							</Grid>
						) : (
							<Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
								<Typography variant="h6">Your cart is empty</Typography>
								<Button
									size="small"
									variant="contained"
									color="error"
									onClick={goBack}
									sx={{ marginTop: '1rem' }}
								>
									Back to Shopping
								</Button>
							</Box>
						)}
					</Box>
				}
			</>
		</Fragment>
	);
};

export default Cart;
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiCallStatus } from "../../utils/constants";
import _ from "lodash";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import CheckoutSummary from "../../components/CheckoutSummary";
import { removeAuthCookies } from "../../api/authentication";
import { useCart } from "react-use-cart";
import {
	fetchAddressCheckoutData,
	fetchCheckoutData,
	fetchOrderCheckoutData
} from "../PageFunctions/checkoutFunctions";
import { createOrderData } from "../PageFunctions/orderFunctions";
import { useAuth0 } from "@auth0/auth0-react";

const clearCookies = async () => {
	await removeAuthCookies();
};

const CheckoutSummaryPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const {items, emptyCart} = useCart();
	const location = useLocation();
	const navigate = useNavigate();
	const [checkoutData, setCheckoutData] = useState(null);
	const [addressCheckoutInfo, setAddressCheckoutInfo] = useState(null);
	const [orderCheckoutInfo, setOrderCheckoutInfo] = useState(null);
	const [salesPeople, setSalesPeople] = useState([]);
	const [countries, setCountries] = useState([]);
	const [provinces, setProvinces] = useState([]);
	const [userAddresses, setUserAddresses] = useState([]);
	const [collectionAddresses, setCollectionAddresses] = useState([]);

	const [checkoutDataCallStatus, setCheckoutDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [orderCheckoutInfoCallStatus, setOrderCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);
	const [addressCheckoutInfoCallStatus, setAddressCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);
	const [createOrderCallStatus, setCreateOrderCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoLoginPage = () => {
		return navigate("/login", {state: {from: location}});
	};
	const gotoCheckoutOrderInformation = () => navigate("/checkout/information");
	const gotoCheckoutBillingAddress = () => navigate("/checkout/billing");
	const gotoCheckoutCollectionAddress = () => navigate("/checkout/collection");
	const gotoCheckoutDeliveryMethod = () => navigate("/checkout/delivery-method");
	const gotoCheckoutShippingAddress = () => navigate("/checkout/shipping");
	const gotoPaymentPage = () => navigate(`/payment`);

	const clearCookiesAsync = async () => {
		await clearCookies();
	};

	const fetchCheckoutDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchCheckoutData({
			auth0Token,
			setCheckoutData,
			setStatusInProgress: () => setCheckoutDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setCheckoutDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setCheckoutDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setCheckoutDataCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchCheckoutAddressDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchAddressCheckoutData({
			auth0Token,
			setAddressCheckoutInfo,
			setStatusInProgress: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchCheckoutOrderDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchOrderCheckoutData({
			auth0Token,
			setOrderCheckoutInfo,
			setStatusInProgress: () => setOrderCheckoutInfoCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setOrderCheckoutInfoCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setOrderCheckoutInfoCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setOrderCheckoutInfoCallStatus(ApiCallStatus.Error)
		});
	};

	const createOrderDataAsync = async (checkoutData) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		if (!!items) {
			await createOrderData({
				auth0Token,
				salesPersonCode: checkoutData.salesPersonCode,
				logisticsMethod: checkoutData.logisticsMethod,
				billingAddressId: checkoutData.billingAddressId,
				shippingAddressId: checkoutData.shippingAddressId,
				collectionAddressId: checkoutData.collectionAddressId,
				items,
				setStatusInProgress: () => setCreateOrderCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setCreateOrderCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setCreateOrderCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setCreateOrderCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const authorizedPageLoad = async () => {
		await Promise.all([
			fetchCheckoutAddressDataAsync()
		]);
	};

	const unAuthorizedPageLoad = async () => {
		await Promise.all([
			fetchCheckoutOrderDataAsync(),
			fetchCheckoutDataAsync()
		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				clearCookiesAsync();
				gotoLoginPage();
			} else {
				authorizedPageLoad();
				unAuthorizedPageLoad();
			}
		}

	}, [genericDataLoaded, isAuthenticated, isLoading]);

	useEffect(() => {
		if (addressCheckoutInfoCallStatus === ApiCallStatus.Succeeded && !!addressCheckoutInfo) {
			let userAddressesTemp = [];
			let collectionAddressesTemp = [];
			let countries = [];
			let provinces = [];

			if (addressCheckoutInfo?.countries) {
				const countriesInAlphabeticalOrder = _.sortBy(
					addressCheckoutInfo.countries,
					({name}) => name
				);
				countries = _.sortBy(countriesInAlphabeticalOrder, ({name}) =>
					name === "South Africa" ? 0 : 1
				);
			}

			if (addressCheckoutInfo?.provinces) {
				provinces = addressCheckoutInfo.provinces;
			}

			if (addressCheckoutInfo?.userAddresses) {
				userAddressesTemp = addressCheckoutInfo.userAddresses;
			}

			if (addressCheckoutInfo?.pickupLocations) {
				collectionAddressesTemp = addressCheckoutInfo.pickupLocations;
			}

			setProvinces(provinces);
			setCountries(countries);
			setUserAddresses(userAddressesTemp);
			setCollectionAddresses(collectionAddressesTemp);
		}
	}, [addressCheckoutInfo, addressCheckoutInfoCallStatus]);

	useEffect(() => {
		if (orderCheckoutInfoCallStatus === ApiCallStatus.Succeeded && !!orderCheckoutInfo?.salesPeople) {
			let salesPeopleTemp;
			salesPeopleTemp = orderCheckoutInfo.salesPeople;
			setSalesPeople(salesPeopleTemp);
		}
	}, [orderCheckoutInfo?.salesPeople, orderCheckoutInfoCallStatus]);

	useEffect(() => {
		if (createOrderCallStatus === ApiCallStatus.Succeeded) {
			emptyCart();
			gotoPaymentPage();
		}
	}, [createOrderCallStatus]);

	const allLoadingStates = [
		checkoutDataCallStatus,
		orderCheckoutInfoCallStatus,
		addressCheckoutInfoCallStatus,
		createOrderCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Container maxWidth="lg">
			<CheckoutSummary
				accountType={genericPageContent?.accountType}
				tecfinityAccountNumber={genericPageContent?.tecfinityAccountNumber}
				tecfinityAccountName={genericPageContent?.tecfinityAccountName}
				checkoutData={checkoutData}
				countries={countries}
				provinces={provinces}
				userAddresses={userAddresses}
				collectionAddresses={collectionAddresses}
				salesPeople={salesPeople}
				gotoCheckoutOrderInformation={gotoCheckoutOrderInformation}
				gotoCheckoutBillingAddress={gotoCheckoutBillingAddress}
				gotoCheckoutCollectionAddress={gotoCheckoutCollectionAddress}
				gotoCheckoutDeliveryMethod={gotoCheckoutDeliveryMethod}
				gotoCheckoutShippingAddress={gotoCheckoutShippingAddress}
				onSubmit={createOrderDataAsync}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Container>
	);
};

export default CheckoutSummaryPage;

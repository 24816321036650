import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ApiCallStatus, PaymentType } from "../../utils/constants";
import { createEftPaymentData, fetchPaymentOrderData } from "../PageFunctions/paymentFunctions";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import PaymentEft from "../../components/PaymentEft";
import PaymentPayfast from "../../components/PaymentPayfast";


const PaymentPayfastPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const location = useLocation();
	const navigate = useNavigate();
	const [paymentOrderInfo, setPaymentOrderInfo] = useState(null);

	const [paymentOrderInfoCallStatus, setPaymentOrderInfoCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoLoginPage = () => navigate("/login", {state: {from: location}});

	const fetchPaymentOrderDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchPaymentOrderData({
			auth0Token,
			paymentType: PaymentType.PayFast,
			setPaymentOrderInfo,
			setStatusInProgress: () => setPaymentOrderInfoCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setPaymentOrderInfoCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setPaymentOrderInfoCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setPaymentOrderInfoCallStatus(ApiCallStatus.Error)
		});
	};

	const authorizedPageLoad = async () => {

	};

	const unAuthorizedPageLoad = async () => {
		await fetchPaymentOrderDataAsync();
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				gotoLoginPage();
			} else {
				authorizedPageLoad();
				unAuthorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isAuthenticated, isLoading]);

	const allLoadingStates = [
		paymentOrderInfoCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Container maxWidth="lg">
			<PaymentPayfast
				paymentOrderInfo={paymentOrderInfo}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Container>
	);
};

export default PaymentPayfastPage;
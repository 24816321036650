import React, { useEffect, useState } from "react";
import "./style.css";
import { useAuth0 } from "@auth0/auth0-react";
import { ApiCallStatus } from "../../utils/constants";
import { fetchProductsData, fetchProductsStockInfo } from "../../main-component/PageFunctions/productFunctions";
import { fetchProductsPrices } from "../../main-component/PageFunctions/priceFunctions";
import { useArgusCart } from "../../contexts/ArgusCartContext";
import { Backdrop, Box, Button, CircularProgress, Container, Grid, Typography } from "@mui/material";
import CategorySlider from "../CategorySlider";
import { find } from "lodash";
import GridItem from "../GridItem";

const FindProductsByCategory = ({
	categoriesAndTypes,
	customerCategory
}) => {
	const {handleAddToCart} = useArgusCart();
	const {getAccessTokenSilently, isAuthenticated} = useAuth0();

	const [products, setProducts] = useState(null);
	const [activeCategory, setActiveCategory] = useState(null);

	const [productCategoryLink, setProductCategoryLink] = useState(null);
	const [pricingData, setPricingData] = useState(null);
	const [stockInfo, setStockInfo] = useState(null);

	const [productsCallStatus, setProductsCallStatus] = useState(ApiCallStatus.NotStarted);
	const [pricesCallStatus, setPricesCallStatus] = useState(ApiCallStatus.NotStarted);
	const [stockInfoCallStatus, setStockInfoCallStatus] = useState(ApiCallStatus.NotStarted);

	const fetchProductsDataAsync = async (category) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchProductsData({
			auth0Token,
			productCategory: category,
			pageNumber: 0,
			setProducts,
			setAllProductIds: () => { },
			setTotal: () => { },
			setPageSize: () => { },
			setPageNumber: () => { },
			setStatusInProgress: () => setProductsCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setProductsCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setProductsCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setProductsCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchProductStockInfoAsync = async () => {
		const productSkus = products.map((x) => x.sku);
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchProductsStockInfo({
			auth0Token,
			productSkus,
			setStockInfo,
			setStatusInProgress: () => setStockInfoCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setStockInfoCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setStockInfoCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setStockInfoCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchPricesDataAsync = async () => {
		const productSkus = products.map((x) => x.sku);
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchProductsPrices({
			auth0Token,
			productSkus,
			setPricingData,
			setStatusInProgress: () => setPricesCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setPricesCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setPricesCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setPricesCallStatus(ApiCallStatus.Error)
		});
	};

	const handleAddToCartClick = (product) => {
		handleAddToCart({
			id: product.productId,
			name: product.name,
			vendor: product.vendor,
			image: product.image,
			sku: product.sku
		});
	};

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	const handleActiveCategoryChange = (category) => {
		setActiveCategory(category);
	}

	useEffect(() => {
		scrollTop();
	}, []);

	useEffect(() => {
		if (!!activeCategory) {

			const categorySlug = activeCategory
				.toLowerCase()
				.replaceAll(" ", "_")
				.trim();

			setProductCategoryLink(encodeURI(`/products/${categorySlug}`));
			fetchProductsDataAsync(categorySlug);

		} else if(!activeCategory) {
			setActiveCategory("Service parts")
		}
	}, [activeCategory]);

	useEffect(() => {
		if (productsCallStatus === ApiCallStatus.Succeeded && !!products) {
			fetchPricesDataAsync();
			fetchProductStockInfoAsync();
		}
	}, [products, productsCallStatus]);

	const allLoadingStates = [
		productsCallStatus,
		pricesCallStatus,
		stockInfoCallStatus
	];

	const loading = allLoadingStates.includes(ApiCallStatus.InProgress);

	return (
		<Container maxWidth="lg">
			<Box sx={{ mt: 4, p: 2 }}>
				<Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
					Find Part by Category
				</Typography>

				<Box sx={{ height: '32px' }} />
			</Box>

			<CategorySlider
				categoriesAndTypes={categoriesAndTypes}
				handleActiveCategoryChange={handleActiveCategoryChange}
				activeCategory={activeCategory}
			/>

			<Box sx={{ mt: 4, p: 2 }}>
				<Box sx={{ height: '32px' }} />

				<Grid container alignItems="center" justifyContent="space-between">
					<Grid item xs={12} sm={6}>
						<Typography
							variant="h5"
							sx={{ borderLeft: "3px solid red", pl: 1 }}
						>
							{activeCategory}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} textAlign="right">
						<Button
							color="error"
							size="small"
							variant="contained"
							href={productCategoryLink}
							sx={{ mt: { xs: 1, sm: 0 } }}
						>
							View all
						</Button>
					</Grid>
				</Grid>

				<Box sx={{ height: '32px' }} />

				<Box sx={{ p: { xs: 1, md: 3 } }}>
					<Grid container spacing={2}>
						{!!products && !!pricingData && products.slice(0, 4).map((product, itemNumber) => {
							const priceObject = find(pricingData, p => p.sku === product.sku);
							const stockInfoObject = find(stockInfo, p => p.sku === product.sku);
							const promoPrice = priceObject?.promo ?? 0;
							const promoInvoicePrice = priceObject?.promoInvoicePrice ?? 0;

							let itemPrice = priceObject?.price ?? 0;
							let invoicePrice = priceObject?.invoicePrice ?? 0;

							return (
								<Grid item xs={12} sm={6} md={3} key={itemNumber}>
									<GridItem
										key={itemNumber}
										title={product.name}
										price={itemPrice}
										invoicePrice={invoicePrice}
										promo={promoPrice}
										promoInvoicePrice={promoInvoicePrice}
										customerCategory={customerCategory}
										vendor={product.vendor}
										image={product.image}
										productId={product.productId}
										vendorImage={product.vendorImage}
										handle={product.handle}
										vendorDescription={product.vendorDescription}
										quality={product.quality}
										fitment={product.fitment}
										availability={stockInfoObject?.quantityOnHand ?? product.availability}
										warranty={product.warranty}
										warrantyText={product.warrantyText}
										applicationPosition={product.applicationPosition}
										applicationPositionImage={product.applicationPositionImage}
										bodyHtml={product.html}
										sku={product.sku}
										addToCart={() => handleAddToCart(product)}
									/>
								</Grid>
							);
						})}
					</Grid>
				</Box>

				<Backdrop
					sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={loading}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			</Box>
		</Container>
		// <section>
		// 	{!!categoriesAndTypes && !selectedCategory && (
		// 		<article>
		// 			<Swiper
		// 				modules={[Autoplay, Navigation]}
		// 				spaceBetween={isDesktopOrLaptop ? 70 : 0}
		// 				slidesPerView={isDesktopOrLaptop ? 1 : 4}
		// 				navigation
		// 				loop={true}
		// 				pagination={{clickable: true}}
		// 				scrollbar={{draggable: true}}
		// 				autoplay={{
		// 					delay: 5000,
		// 					disableOnInteraction: true
		// 				}}
		// 			>
		// 				{categoriesAndTypes.map((category, index) => {
		// 					const categoryName = capitalize(
		// 						category.category.replaceAll("_", " ").toLowerCase()
		// 					);
		//
		// 					return (
		// 						<SwiperSlide key={index}>
		// 							<article>
		// 								<Link
		// 									to="#"
		// 									onClick={() => handleActiveCategoryChange(categoryName)}
		// 									className="category_container"
		// 								>
		// 									<h1 key={index} className="product_category_heading">
		// 										{categoryName}
		// 									</h1>
		//
		// 								</Link>
		// 							</article>
		// 						</SwiperSlide>
		// 					);
		// 				})}
		// 			</Swiper>
		// 		</article>
		// 	)}
		// 	{!!products && !!pricingData &&
		// 		<Grid container>
		// 			<Grid.Row>
		// 				<Grid.Column width={8}>
		// 					<h1 className="products_home_heading">{activeCategory}</h1>
		// 				</Grid.Column>
		// 				<Grid.Column width={8} textAlign="right">
		// 					<Button
		// 						as={Link}
		// 						className="red-button"
		// 						to={productCategoryLink}
		// 					>
		// 						View all products
		// 					</Button>
		// 				</Grid.Column>
		// 			</Grid.Row>
		// 			{/*<Grid.Row>*/}
		// 			{/*	<Grid.Column width={16}>*/}
		// 			{/*		<Card.Group itemsPerRow={isDesktopOrLaptop ? 1 : 5}>*/}
		// 			{/*			{products.map((product) => {*/}
		// 			{/*				const priceObject = find(pricingData, p => p.sku === product.sku);*/}
		// 			{/*				const itemPrice = priceObject?.price ?? 0;*/}
		// 			{/*				const invoicePrice = priceObject?.invoicePrice ?? 0;*/}
		// 			{/*				return { product, itemPrice, invoicePrice };*/}
		// 			{/*			})*/}
		// 			{/*				.sort((a, b) => a.product.globalRanking - b.product.globalRanking)*/}
		// 			{/*				.slice(0, 5)*/}
		// 			{/*				.map(({ product, itemPrice, invoicePrice }, index) => (*/}
		// 			{/*					<GridItem*/}
		// 			{/*						key={index}*/}
		// 			{/*						title={product.name}*/}
		// 			{/*						price={itemPrice}*/}
		// 			{/*						invoicePrice={invoicePrice}*/}
		// 			{/*						customerCategory={customerCategory}*/}
		// 			{/*						vendor={product.vendor}*/}
		// 			{/*						image={product.image}*/}
		// 			{/*						productId={product.productId}*/}
		// 			{/*						vendorImage={product.vendorImage}*/}
		// 			{/*						handle={product.handle}*/}
		// 			{/*						vendorDescription={product.vendorDescription}*/}
		// 			{/*						quality={product.quality}*/}
		// 			{/*						fitment={product.fitment}*/}
		// 			{/*						availability={product.availability}*/}
		// 			{/*						warranty={product.warranty}*/}
		// 			{/*						warrantyText={product.warrantyText}*/}
		// 			{/*						applicationPosition={product.applicationPosition}*/}
		// 			{/*						applicationPositionImage={product.applicationPositionImage}*/}
		// 			{/*						bodyHtml={product.html}*/}
		// 			{/*						sku={product.sku}*/}
		// 			{/*						addToCart={handleAddToCart}*/}
		// 			{/*					/>*/}
		// 			{/*				))*/}
		// 			{/*			}*/}
		// 			{/*		</Card.Group>*/}
		//
		// 			{/*	</Grid.Column>*/}
		// 			{/*</Grid.Row>*/}
		// 		</Grid>
		// 	}
		// 	{!!productAddedInformation && (
		// 		<ConfirmationModal
		// 			open={!!productAddedInformation}
		// 			confirmLabel="Go to cart"
		// 			rejectLabel="Continue shopping"
		// 			heading="Product added"
		// 			closeModal={handleProductAddedModalClose}
		// 			content={
		// 				<Grid container>
		// 					<Grid.Row>
		// 						<Grid.Column width={16}>
		// 							<p className="h6">{productAddedInformation.name}</p>
		// 						</Grid.Column>
		// 					</Grid.Row>
		// 					<Grid.Row>
		// 						<Grid.Column width={16}>
		// 							<Image src={productAddedInformation.image}/>
		// 						</Grid.Column>
		// 					</Grid.Row>
		// 				</Grid>
		// 			}
		// 		/>
		// 	)}
		// 	<Backdrop
		// 		sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
		// 		open={pageIsLoading}
		// 	>
		// 		<CircularProgress color="inherit"/>
		// 	</Backdrop>
		// </section>
	);
};

export default FindProductsByCategory;

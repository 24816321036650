import React, { useEffect, useState } from 'react';
import { startCase, cloneDeep, toLower } from "lodash";
import { GridProducts } from "../GridProducts";
import "./style.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";
import CategorySlider from "../CategorySlider";
import GridFilters from "../GridFilters";
import { Box, Button, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { GridPromotionProducts } from "../GridPromotionProducts";

const PromotionProductGrid = ({
    promotionProducts,
    stockInfo,
    pricingData,
    customerCategory,
    pageNumber,
    pageSize,
    total,
    handlePageNumberChange
}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    const isMobile = useMediaQuery({
        query: '(min-width: 200px) and (max-width: 1000px) '
    });

    useEffect(() => {
        scrollTop();
    }, []);

    return (
        <Box sx={{ padding: 2, minHeight: "54vh" }}>
            {total === 0 ? (
                <Grid container spacing={2} sx={{ verticalAlign: "middle", alignContent: "center", p: 10}}>
                    <Grid item xs={12}>
                        <Typography variant="body2" align="center">No products matching your criteria.</Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => navigate(-1)}
                            size="small"
                        >
                            Try again
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12} className="mb-5">
                        <GridPromotionProducts
                            promotionProducts={promotionProducts}
                            pricingData={pricingData}
                            stockInfo={stockInfo}
                            customerCategory={customerCategory}
                            pageNumber={pageNumber}
                            handlePageNumberChange={handlePageNumberChange}
                            totalResults={total}
                            pageSize={pageSize}
                            itemsPerRow={isMobile ? 1 : 4}
                        />
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default PromotionProductGrid;
import React, { Suspense } from "react";
import { createRoot } from 'react-dom/client';
import App from "./main-component/App/App";
import "./index.css";
import { CartProvider } from "react-use-cart";
import { ArgusCartProvider } from "./contexts/ArgusCartContext";
import ReactGA from 'react-ga4';

const container = document.getElementById('root');

ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);

const root = createRoot(container);

root.render(
	<Suspense fallback={<div>Loading...</div>}>
		<React.StrictMode>
			<CartProvider>
				<ArgusCartProvider>
					<App/>
				</ArgusCartProvider>
			</CartProvider>
		</React.StrictMode>
	</Suspense>
);

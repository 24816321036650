import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, Link, Modal, Typography } from "@mui/material";
import { storageKeys } from "../../api/storageKeys";
import { getItem, removeItem } from "../../utils/storageHelper";
import { createSearchParams, useNavigate } from "react-router-dom";
import useSystemHook from "../../hooks/SystemHook";
import CloseIcon from "@mui/icons-material/Close";

const modalStyles = {
	desktop: { width: "35%", padding: 4 },
	tablet: { width: "70%", padding: 3 },
	mobile: { width: "90%", padding: 2 },
};

const SavedVehicleModal = ({ open, setOpen, openSavedVehiclesModal, setClose }) => {
	const { screenSize } = useSystemHook();
	const navigate = useNavigate();
	const [currentVehicle, setCurrentVehicle] = useState(null);
	const [currentVehicleName, setCurrentVehicleName] = useState("");

	useEffect(() => {
		const vehicleString = getItem(storageKeys.GUEST_CURRENT_VEHICLE);
		if (vehicleString) setCurrentVehicle(JSON.parse(vehicleString));
	}, []);

	useEffect(() => {
		if (currentVehicle) {
			const { make = "", model = "", variant = "", year = "" } = currentVehicle;
			setCurrentVehicleName(`${make} ${model} ${variant} ${year}`);
		}
	}, [currentVehicle]);

	const closeModal = () => setClose();

	const handleRemoveVehicleClick = () => {
		removeItem(storageKeys.GUEST_CURRENT_VEHICLE);
		closeModal();
		navigate(0);
	};

	const handleSearchForPartsClick = async () => {
		const vehicle = JSON.parse(getItem(storageKeys.GUEST_CURRENT_VEHICLE) || "{}");
		if (!vehicle) return closeModal();

		const params = vehicle.vyid
			? { vehicle_year_id: vehicle.vyid }
			: {
				make: vehicle.make,
				model: vehicle.model,
				variant: vehicle.variant,
				year: vehicle.year,
			};

		await navigate({ pathname: "/products", search: `?${createSearchParams(params)}` });
		closeModal();
	};

	const handleChangeVehicleClick = () => openSavedVehiclesModal();

	const commonContent = (
		<>
			<Grid item xs={12} textAlign="center">
				<Typography variant="caption" sx={{ color: "#555" }}>
					This will allow us to find parts specifically for your vehicle.
				</Typography>
			</Grid>

			<Grid item xs={12} textAlign="center">
				<Link href="#" fontSize="small" onClick={handleChangeVehicleClick} sx={{ textDecoration: "none", color: "#0073e6" }}>
					Choose a different vehicle
				</Link>
			</Grid>

			<Grid item xs={12} textAlign="center">
				<Link href="#" fontSize="small" onClick={handleRemoveVehicleClick} sx={{ textDecoration: "none", color: "#0073e6" }}>
					Browse without a vehicle
				</Link>
			</Grid>

			<Grid item xs={12} textAlign="center">
				<Button variant="contained" color="error" onClick={handleSearchForPartsClick} size="small" sx={{ width: "100%", mt: 2 }}>
					Search for parts
				</Button>
			</Grid>
		</>
	);

	return (
		<Modal open={open} onClose={closeModal}>
			<Box
				sx={{
					...modalStyles[screenSize],
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					bgcolor: "#FFFFFF",
					boxShadow: 24,
					borderRadius: 2,
				}}
			>
				<Grid container justifyContent="space-between" alignItems="center" sx={{ paddingBottom: 2 }}>
					<Typography variant="body2" fontWeight="bold">
						{currentVehicleName}
					</Typography>
					<IconButton onClick={closeModal} size="small">
						<CloseIcon fontSize="small" />
					</IconButton>
				</Grid>
				<Grid container spacing={2} sx={{ mt: 1 }}>
					{commonContent}
				</Grid>
			</Box>
		</Modal>
	);
};

export default SavedVehicleModal;

import React, { useEffect } from "react";
import PageHeader from "../PageHeader";
import { Avatar, Box, Button, Container, Paper, Typography } from "@mui/material";
import useSystemHook from "../../hooks/SystemHook";
import { ScreenSize } from "../../utils/constants";

const PaymentEft = ({ paymentOrderInfo, onSubmit }) => {
	const { screenSize } = useSystemHook();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<PageHeader title="Payment" />
			<Box
				sx={{
					minHeight: screenSize === ScreenSize.Desktop ? "100vh" : "50vh",
					backgroundColor: "#f7f7f7",
					padding: screenSize === ScreenSize.Desktop ? "2rem" : "16px",
				}}
			>
				<Container maxWidth={screenSize === ScreenSize.Desktop ? "md" : "sm"}>
					<Paper elevation={3} sx={{ padding: screenSize === ScreenSize.Desktop ? "2rem" : "16px", textAlign: "center" }}>
						<Avatar
							src="https://argusweb.azureedge.net/argus-web/payment/eft.svg"
							alt="EFT Payment"
							sx={{
								width: screenSize === ScreenSize.Desktop ? 100 : 80,
								height: screenSize === ScreenSize.Desktop ? 100 : 80,
								margin: "0 auto",
								mb: screenSize === ScreenSize.Desktop ? "1.5rem" : "16px",
							}}
						/>
						<Typography variant="body1" sx={{ fontWeight: "bold", mb: screenSize === ScreenSize.Desktop ? "1rem" : "8px" }}>
							Direct EFT
						</Typography>
						<Typography variant={screenSize === ScreenSize.Desktop ? "body2" : "caption"} sx={{ mb: "0.5rem" }}>
							Please note that shipment will only take place once the payment reflects in our account.
						</Typography>
						<Typography variant={screenSize === ScreenSize.Desktop ? "body2" : "caption"} sx={{ mb: screenSize === ScreenSize.Desktop ? "1.5rem" : "16px" }}>
							(Payment is through Nedbank or Standard Bank)
						</Typography>

						{!!paymentOrderInfo && (
							<Button
								variant="contained"
								color="error"
								size="small"
								onClick={onSubmit}
								sx={{
									width: screenSize === ScreenSize.Desktop ? "50%" : "100%",
									mt: screenSize === ScreenSize.Desktop ? "1.5rem" : "16px",
								}}
							>
								Pay
							</Button>
						)}
					</Paper>
				</Container>
			</Box>
		</>
	);
};

export default PaymentEft;

import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Modal,
	Typography,
	TextField,
	Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ApiCallStatus } from "../../utils/constants";
import {
	fetchManufacturers,
	fetchModels,
	fetchVariants,
} from "../../main-component/PageFunctions/vehicleFunctions";
import { fetchVehicleDetails } from "../../api/vehicleController";
import useSystemHook from "../../hooks/SystemHook";

const modalStyles = {
	desktop: {
		width: "35%",
		padding: 4,
	},
	tablet: {
		width: "70%",
		padding: 3,
	},
	mobile: {
		width: "90%",
		padding: 2,
	},
};

const ChooseVehicleModal = ({
	open,
	setClose,
	openSavedVehiclesModal,
	createUserVehicleAsync,
}) => {
	const { screenSize } = useSystemHook();
	const [yearData, setYearData] = useState([]);
	const [manufacturerData, setManufacturerData] = useState([]);
	const [modelData, setModelData] = useState([]);
	const [variantData, setVariantData] = useState([]);
	const [userInput, setUserInput] = useState({ manufacturer: "", model: "", variant: "", year: "" });
	const [vehicleToSave, setVehicleToSave] = useState(null);

	const fetchDataAsync = async (fetchFunction, params, setters) => {
		await fetchFunction({
			...params,
			setStatusInProgress: () => setters[0](ApiCallStatus.InProgress),
			setStatusSuccess: () => setters[0](ApiCallStatus.Succeeded),
			setStatusFailed: () => setters[0](ApiCallStatus.Failed),
			setStatusError: () => setters[0](ApiCallStatus.Error),
			...setters.slice(1),
		});
	};

	const handleUserVehicleChange = (name) => (event, newValue) => {
		const updatedInput = { ...userInput, [name]: newValue };
		if (name === "manufacturer") updatedInput.model = updatedInput.variant = updatedInput.year = "";
		if (name === "model") updatedInput.variant = updatedInput.year = "";
		if (name === "variant") updatedInput.year = "";
		setUserInput(updatedInput);
	};

	const closeModal = () => openSavedVehiclesModal();

	const handleModalClose = (choice) => async () => {
		if (choice === "confirm" && userInput.manufacturer) {
			await fetchDataAsync(fetchVehicleDetails, {
				make: userInput.manufacturer,
				model: userInput.model,
				variant: userInput.variant,
				year: userInput.year,
				setVehicleToSave,
			});
		}
		if (choice === "cancel") closeModal();
	};

	useEffect(() => {
		fetchDataAsync(fetchManufacturers, { year: userInput.year }, [setManufacturerData]);
		if (userInput.manufacturer) fetchDataAsync(fetchModels, { manufacturer: userInput.manufacturer, year: userInput.year }, [setModelData]);
		if (userInput.model) fetchDataAsync(fetchVariants, { manufacturer: userInput.manufacturer, model: userInput.model, year: userInput.year }, [setVariantData]);
	}, [userInput]);

	useEffect(() => {
		if (vehicleToSave) createUserVehicleAsync(vehicleToSave);
	}, [vehicleToSave]);

	const loading = [manufacturerData, modelData, variantData, yearData].some((status) => status === ApiCallStatus.InProgress);

	return (
		<Modal open={open} onClose={handleModalClose("cancel")}>
			<Box
				sx={{
					...modalStyles[screenSize],
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					bgcolor: "background.paper",
					boxShadow: 24,
					borderRadius: 2,
				}}
			>
				<Grid container justifyContent="space-between" alignItems="center" sx={{ pb: 2 }}>
					<Typography variant="body2" fontWeight="bold">Choose your vehicle</Typography>
					<IconButton onClick={handleModalClose("cancel")} size="small">
						<CloseIcon fontSize="small" />
					</IconButton>
				</Grid>
				<Typography variant="caption" sx={{ mt: 1, color: "#555" }}>
					Specify make, model, variant, and year to get the most accurate parts for your vehicle.
				</Typography>
				<Box component="form" sx={{ mt: 3 }}>
					<Grid container spacing={2}>
						{["manufacturer", "model", "variant", "year"].map((field, index) => (
							<Grid item xs={6} key={field}>
								<Autocomplete
									loading={loading}
									value={userInput[field]}
									onChange={handleUserVehicleChange(field)}
									options={
										field === "manufacturer"
											? manufacturerData
											: field === "model"
												? modelData
												: field === "variant"
													? variantData
													: yearData
									}
									getOptionLabel={(option) => option}
									size="small"
									disabled={loading}
									renderInput={(params) => (
										<TextField
											{...params}
											label={field.charAt(0).toUpperCase() + field.slice(1)}
											variant="outlined"
											sx={{
												"& .MuiOutlinedInput-root": {
													"& fieldset": { borderColor: "#000000" },
													"&:hover fieldset": { borderColor: "#000000" },
													"&.Mui-focused fieldset": { borderColor: "#000000" },
												},
											}}
										/>
									)}
								/>
							</Grid>
						))}
					</Grid>
				</Box>
				<Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 3 }}>
					<Grid item>
						<Button
							variant="contained"
							color="error"
							size="small"
							onClick={handleModalClose("confirm")}
							disabled={loading}
						>
							{loading ? <CircularProgress size={24} /> : "Save"}
						</Button>
					</Grid>
					<Grid item>
						<Button onClick={handleModalClose("cancel")} size="small">
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
};

export default ChooseVehicleModal;

import React, { useEffect, useState } from 'react';
import { startCase, cloneDeep, toLower } from "lodash";
import { GridProducts } from "../GridProducts";
import "./style.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";
import CategorySlider from "../CategorySlider";
import GridFilters from "../GridFilters";
import { Box, Button, Grid, Paper, Tooltip, Typography } from "@mui/material";

const ProductGrid = ({
    products,
    pricingData,
    stockInfo,
    customerCategory,
    categoriesAndTypes,
    activeVendor,
    filters,
    activeFilters,
    pageNumber,
    pageSize,
    total,
    handlePageNumberChange,
    updateQueryParameters
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [vendorOptions, setVendorOptions] = useState([]);
    const [makeOptions, setMakeOptions] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);
    const [variantOptions, setVariantOptions] = useState([]);
    const [yearOptions, setYearOptions] = useState([]);
    const [productCategories, setProductCategories] = useState([]);
    const [productCategoriesTruncated, setProductCategoriesTruncated] = useState([]);
    const [productCategoriesListToUse, setProductCategoriesListToUse] = useState([]);
    const [productTypes, setProductTypes] = useState([]);
    const [productTypesTruncated, setProductTypesTruncated] = useState([]);
    const [productTypesListToUse, setProductTypesListToUse] = useState([]);
    const [showAllCategories, setShowAllCategories] = useState(false);
    const [showAllTypes, setShowAllTypes] = useState(false);

    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    const isMobile = useMediaQuery({
        query: '(min-width: 200px) and (max-width: 1000px) '
    });

    const submitHandler = async () => {
        const searchParams = new URLSearchParams(location.search);
        if (!!activeFilters?.make) searchParams.set("make", activeFilters.make);
        if (!!activeFilters?.model) searchParams.set("model", activeFilters.model);
        if (!!activeFilters?.variant) searchParams.set("variant", activeFilters.variant);
        if (!!activeFilters?.year) searchParams.set("year", activeFilters.year);
        if (!!activeFilters?.vendor) searchParams.set("vendor", activeFilters.vendor);
        if (!!activeFilters?.productCategory) searchParams.set("product_category", activeFilters.productCategory);
        if (!!activeFilters?.productType) searchParams.set("product_type", activeFilters.productType);

        await updateQueryParameters(searchParams.toString());
    };

    const clearFilters = () => {
        const urlParams = new URLSearchParams(window.location.search);

        const paramsToClear = ['make', 'model', 'variant', 'year', 'vendor'];

        paramsToClear.forEach(param => {
            urlParams.delete(param);
        });

        window.location.href = `${window.location.pathname}?${urlParams.toString()}`;
    }

    const handleFilterChange = async (e) => {
        const activeFiltersCopy = cloneDeep(activeFilters);
        if (!!e.target.checked) {
            activeFiltersCopy[e.target.name] = e.target.value;
        } else {
            activeFiltersCopy[e.target.name] = null;
        }

        if (e.target.name === "make") {
            activeFiltersCopy.model = null;
            activeFiltersCopy.variant = null;
            activeFiltersCopy.year = null;
        }
        else if (e.target.name === "model") {
            activeFiltersCopy.variant = null;
            activeFiltersCopy.year = null;
        }
        else if (e.target.name === "variant") {
            activeFiltersCopy.year = null;
        }

        const searchParams = new URLSearchParams();

        if (!!activeFiltersCopy?.make) searchParams.set("make", activeFiltersCopy.make);
        if (!!activeFiltersCopy?.model) searchParams.set("model", activeFiltersCopy.model);
        if (!!activeFiltersCopy?.variant) searchParams.set("variant", activeFiltersCopy.variant);
        if (!!activeFiltersCopy?.year) searchParams.set("year", activeFiltersCopy.year);
        if (!!activeFiltersCopy?.vendor) searchParams.set("vendor", activeFiltersCopy.vendor);

        window.location.href = `${location.pathname}${!!searchParams.size > 0 ? "?" : ""}${searchParams.toString()}`;
    }

    const showMoreCategories = () => {
        setShowAllCategories(true);
        setProductCategoriesListToUse(productCategories);
    }

    const showLessCategories = () => {
        setShowAllCategories(false);
        setProductCategoriesListToUse(productCategoriesTruncated);
    }

    const showMoreTypes = () => {
        setShowAllTypes(true);
        setProductTypesListToUse(productTypes);
    }

    const showLessTypes = () => {
        setShowAllTypes(false);
        setProductTypesListToUse(productTypesTruncated);
    }

    const changePageCategory = value => {
        window.location.href = `/products/${value}`;
    }

    const changePageProductType = value => {
        const currentPath = location.pathname;
        const pathParts = currentPath.split('/');
        const category = pathParts[2];
        window.location.href = `/products/${category}/${encodeURIComponent(value)}`;
    }

    const clearPageProductType = () => {
        const currentPath = location.pathname;
        const pathParts = currentPath.split('/');
        const category = pathParts[2];
        window.location.href = `/products/${category}`;
    }

    const handleActiveCategoryChange = (categoryName) => {
        window.location.href = `/products/${categoryName.replaceAll(" ", "_")}`;
    }

    useEffect(() => {
        scrollTop();
    }, []);

    useEffect(() => {
        if (!filters || Object.keys(filters).length < 1) return;

        let vendorOptions = [];
        let makeOptions = [];
        let modelOptions = [];
        let variantOptions = [];
        let yearOptions = [];
        let productCategoryOptions = [];
        let productTypeOptions = [];

        if (!!filters.vendors && filters.vendors.length > 0) {
            vendorOptions = filters.vendors.filter(c => !!c)
                                   .map((v, index) => {
                                       return {
                                           key: index,
                                           text: startCase(v.toLowerCase()),
                                           value: toLower(v)
                                       };
                                   })
                                   .sort((a, b) => {
                                       if (a.text < b.text) return -1;
                                       if (a.text > b.text) return 1;
                                       return 0;
                                   });
            setVendorOptions(vendorOptions);
        }

        if (!!filters.makes && filters.makes.length > 0) {
            makeOptions = filters.makes.filter(c => !!c)
                                 .map((m, index) => {
                                     return {
                                         key: index,
                                         text: startCase(m.toLowerCase()),
                                         value: toLower(m)
                                     };
                                 })
                                 .sort((a, b) => {
                                     if (a.text < b.text) return -1;
                                     if (a.text > b.text) return 1;
                                     return 0;
                                 });
            setMakeOptions(makeOptions);
        }

        if (!!filters.models && filters.models.length > 0) {
            modelOptions = filters.models.filter(c => !!c)
                                  .map((m, index) => {
                                      return {
                                          key: index,
                                          text: startCase(m),
                                          value: toLower(m)
                                      };
                                  })
                                  .sort((a, b) => {
                                      if (a.text < b.text) return -1;
                                      if (a.text > b.text) return 1;
                                      return 0;
                                  });
            setModelOptions(modelOptions);
        }

        if (!!filters.variants && filters.variants.length > 0) {
            variantOptions = filters.variants.filter(c => !!c)
                                    .map((m, index) => {
                                        return {
                                            key: index,
                                            text: startCase(m),
                                            value: toLower(m)
                                        };
                                    })
                                    .sort((a, b) => {
                                        if (a.text < b.text) return -1;
                                        if (a.text > b.text) return 1;
                                        return 0;
                                    });
            setVariantOptions(variantOptions);
        }

        if (!!filters.years && filters.years.length > 0) {
            yearOptions = filters.years.filter(c => !!c)
                                 .map((year) => {
                                     return {
                                         key: year,
                                         text: year,
                                         value: year
                                     };
                                 })
                                 .sort((a, b) => {
                                     if (a.value < b.value) return -1;
                                     if (a.value > b.value) return 1;
                                     return 0;
                                 });
            setYearOptions(yearOptions);
        }

        if (!!filters.productCategories && filters.productCategories.length > 0) {
            productCategoryOptions = filters.productCategories.filter(c => !!c)
                                            .map(c => {
                                                return {
                                                    text: startCase(c.replaceAll("_", " ")),
                                                    value: toLower(c)
                                                };
                                            })
                                            .sort((a, b) => {
                                                if (a.value < b.value) return -1;
                                                if (a.value > b.value) return 1;
                                                return 0;
                                            });
            setProductCategories(productCategoryOptions);
            setProductCategoriesTruncated(productCategoryOptions.slice(0, 3));
            setProductCategoriesListToUse(productCategoryOptions.slice(0, 3))
        }

        if (filters.productTypes && filters.productTypes.length > 0) {
            productTypeOptions = filters.productTypes.filter(c => !!c)
                                        .map(c => {
                                            return {
                                                text: startCase(c.replaceAll("_", " ")),
                                                value: toLower(c)
                                            };
                                        })
                                        .sort((a, b) => {
                                            if (a.value < b.value) return -1;
                                            if (a.value > b.value) return 1;
                                            return 0;
                                        });
            setProductTypes(productTypeOptions);
            setProductTypesTruncated(productTypeOptions.slice(0, 3));
            setProductTypesListToUse(productTypeOptions.slice(0, 3));
        }
    }, [filters]);

    return (
        <Box sx={{ padding: 2, minHeight: "54vh" }}>
            {total === 0 ? (
                <Grid container spacing={2} sx={{ verticalAlign: "middle", alignContent: "center", p: 10}}>
                    <Grid item xs={12}>
                        <Typography variant="body2" align="center">No products matching your criteria.</Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => navigate(-1)}
                            size="small"
                        >
                            Try again
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ marginTop: "32px", marginBottom: "32px"}}>
                        {!!categoriesAndTypes &&
                            <CategorySlider categoriesAndTypes={categoriesAndTypes}
                                            handleActiveCategoryChange={handleActiveCategoryChange}
                            />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {!!activeVendor && (
                            <Grid container spacing={2} sx={{ marginBottom: "32px" }}>
                                <Grid item xs={12} md={4}>
                                    <Box
                                        sx={{
                                            padding: 4,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "200px",
                                        }}
                                    >
                                        <Tooltip title={activeVendor.vendor_Description} arrow placement="top">
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    height: "100%",
                                                    width: "100%",
                                                    padding: '8px',
                                                }}
                                            >
                                                <img
                                                    src={activeVendor.vendor_Image}
                                                    alt={activeVendor.vendor_Name}
                                                    style={{ maxHeight: '100%', maxWidth: '100%' }}
                                                />
                                            </Box>
                                        </Tooltip>
                                    </Box>
                                </Grid>

                                {!!activeVendor?.vendor_Description && (
                                    <Grid item xs={12} md={8}>
                                        <Box
                                            sx={{
                                                padding: 4,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                textAlign: "center",
                                            }}
                                        >
                                            <Typography variant="body2" fontWeight="bold" component="p">
                                                {activeVendor.vendor_Description}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>

                    <Grid item xs={12} md={4} className="mb-5">
                        <GridFilters
                            activeFilters={activeFilters}
                            showAllCategories={showAllCategories}
                            showAllTypes={showAllTypes}
                            changePageCategory={changePageCategory}
                            changePageProductType={changePageProductType}
                            clearPageProductType={clearPageProductType}
                            makeOptions={makeOptions}
                            handleFilterChange={handleFilterChange}
                            productCategoriesListToUse={productCategoriesListToUse}
                            variantOptions={variantOptions}
                            yearOptions={yearOptions}
                            showMoreCategories={showMoreCategories}
                            showLessCategories={showLessCategories}
                            modelOptions={modelOptions}
                            vendorOptions={vendorOptions}
                            productTypesListToUse={productTypesListToUse}
                            showMoreTypes={showMoreTypes}
                            showLessTypes={showLessTypes}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <GridProducts
                            products={products}
                            pricingData={pricingData}
                            stockInfo={stockInfo}
                            customerCategory={customerCategory}
                            pageNumber={pageNumber}
                            handlePageNumberChange={handlePageNumberChange}
                            totalResults={total}
                            pageSize={pageSize}
                            itemsPerRow={isMobile ? 1 : 3}
                        />
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default ProductGrid;
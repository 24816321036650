import React, {useEffect, useState} from "react";
import DOMPurify from 'dompurify';
import "./style.css";
import { useArgusCart } from "../../contexts/ArgusCartContext";
import { CustomerCategory, ProductAvailability } from "../../utils/constants";
import { Box, Button, Card, CardContent, CardMedia, Chip, IconButton, Modal, Tooltip, Typography } from "@mui/material";
import cssVariables from "../../utils/cssVariables";
import InfoIcon from "@mui/icons-material/Info";
import { SouthAfricanRand } from "../../utils/helpers";
import { round } from "lodash";
import CloseIcon from "@mui/icons-material/Close";

const GridItem = ({
    title,
    price,
	invoicePrice,
    promo,
    promoInvoicePrice,
    customerCategory,
    vendor,
    image,
    productId,
    vendorImage,
    quality,
    fitment,
    availability,
    warranty,
    warrantyText,
    applicationPosition,
    applicationPositionImage,
    vendorDescription,
    showVehicleSpecificInformation,
    isKiosk,
    bodyHtml,
    sku,
    quantityInCart
}) => {
    const {handleAddToCart} = useArgusCart();
    const [updatedQuantity, setUpdatedQuantity] = useState(quantityInCart);
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const fitmentImage = "https://argusweb.azureedge.net/argusblob01/web/fitment.jpg";
    const qualityImage = "https://argusweb.azureedge.net/argusblob01/web/quality.jpg";
    const availabilityImage = "https://argusweb.azureedge.net/argusblob01/web/availability.jpg";


    const applicationPositionParts = applicationPosition?.split(":");
    if (applicationPositionParts && applicationPositionParts.length > 1) {
        applicationPosition = applicationPositionParts[1].trim();
    }

    let fitmentText = fitment?.split("$");
    let fitmentTextSplit = [];
    if (fitmentText) {
        if (fitmentText.length > 1) {
            fitmentTextSplit = fitmentText[1].split("|");
        }
        else {
            fitmentTextSplit = fitmentText;
        }
    }

    const colourForAvailability = (() => {
        switch (availability) {
            case ProductAvailability.InStock: return "success";
            case ProductAvailability.LowStock: return "warning";
            case ProductAvailability.ThreeToFiveDays: return "info";
            case ProductAvailability.No_Stock: return "error";
            default: return "default";
        }
    })();

    const colourForQuality = (() => {
        switch (quality) {
            case "A+": return "primary";
            case "A": return "info";
            case "B": return "secondary";
            case "Other": return "default";
            default: return "default";
        }
    })();

    const handleAddToCartClick = () => {
        handleAddToCart({
            id: productId,
            name: title,
            vendor,
            image,
            sku
        });
    };

    const handleOpenModal = (content) => {
        setModalContent(content);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setModalContent(null);
    };

    const sanitizedData = () => ({
        __html: DOMPurify.sanitize(bodyHtml)
    });

    useEffect(() => {
        setUpdatedQuantity(Number(quantityInCart));
    }, [quantityInCart]);

    return (
        <Card raised sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column', minWidth: "250px", maxWidth: "250px", width: { xs: '100%', md: '250px' } }}>
            <Box sx={{ position: 'absolute', top: 16, left: 16, zIndex: 10, width: 40, height: 40 }}>
                {vendorImage ? (
                    <Tooltip title={vendorDescription}>
                        <img src={vendorImage} alt={vendor} style={{ height: '100%', width: '100%' }} />
                    </Tooltip>
                ) : (
                    <Box sx={{ height: '100%', width: '100%' }} />
                )}
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: { xs: '150px', md: '200px' }, backgroundColor: '#f9f9f9', cursor: 'pointer' }}
                 onClick={() => navigate(`/product/${sku}`)}>

                <a href={`/product/${sku}`}>
                    <CardMedia component="img"
                               image={image}
                               alt={title}
                               sx={{
                                   maxHeight: { xs: '120px', md: '170px' },
                                   maxWidth: { xs: '100px', md: '130px' },
                               }}
                    />
                </a>
            </Box>

            <Box sx={{ position: 'absolute', top: 16, right: 16, width: 40, height: 40 }}>
                {fitment ? (
                    <IconButton onClick={() => handleOpenModal(<img src={fitmentImage} alt="Fitment" style={{ width: '100%' }} />)}>
                        <img src={fitmentImage} alt="Fitment" style={{ height: '100%', width: '100%' }} />
                    </IconButton>
                ) : (
                    <Box sx={{ height: '100%', width: '100%' }} />
                )}
            </Box>

            <CardContent sx={{ flexGrow: 1 }}>
                <a href={`/product/${sku}`} style={{textDecoration: 'none', color: 'inherit'}}>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            fontSize: cssVariables.fontSizeXS,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            lineHeight: cssVariables.lineHeightXS,
                            maxHeight: '2.8em',
                            minHeight: '2.8em',
                        }}
                    >
                        {title}
                    </Typography>
                </a>

                <Box display="flex" alignItems="center" mb={2} justifyContent="flex-start" sx={{ gap: '4px', flexWrap: { xs: 'wrap', md: 'nowrap' } }}>
                    <Chip
                        size="small"
                        label={availability}
                        color={colourForAvailability || 'default'}
                        onClick={() => handleOpenModal(
                            <img
                                src={availabilityImage}
                                alt="Availability"
                                style={{ width: '100%', height: 'auto', maxWidth: '600px', maxHeight: '400px' }}
                            />
                        )}
                    />
                    {!!warranty && !!warrantyText && (
                        <Tooltip title={warrantyText}>
                            <Chip
                                size="small"
                                label={warranty}
                                color="error"
                                onClick={() => handleOpenModal(warrantyText)}
                            />
                        </Tooltip>
                    )}
                    {quality && (
                        <Chip
                            size="small"
                            label={quality}
                            color={colourForQuality || 'default'}
                            onClick={() => handleOpenModal(
                                <img
                                    src={qualityImage}
                                    alt="Quality"
                                    style={{ width: '100%', height: 'auto', maxWidth: '600px', maxHeight: '400px' }}
                                />
                            )}
                        />
                    )}
                    <IconButton onClick={() => handleOpenModal(<div dangerouslySetInnerHTML={sanitizedData()} />)}>
                        <InfoIcon sx={{ cursor: 'pointer', fontSize: 20 }} />
                    </IconButton>
                </Box>

                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: "bold",
                        textDecoration: promo > 0 ? "line-through" : "none"
                    }}
                >
                    {SouthAfricanRand.format(round(price, 2))}
                </Typography>

                {invoicePrice > 0 && invoicePrice !== price && !promoInvoicePrice && (
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                            fontSize: '0.7rem',
                            textDecoration: promoInvoicePrice > 0 ? "line-through" : "none"
                        }}
                    >
                        Invoice: <strong>{SouthAfricanRand.format(round(invoicePrice, 2))}</strong>
                    </Typography>
                )}

                {promo > 0 && (
                    <Typography variant="body1" color="error" sx={{ fontWeight: "bold", mt: 1 }}>
                        {SouthAfricanRand.format(round(promo, 2))}
                    </Typography>
                )}

                {promoInvoicePrice > 0 && promoInvoicePrice !== promo && (
                    <Typography variant="body2" color="error" sx={{ fontSize: '0.7rem', mt: 1 }}>
                        Invoice: <strong>{SouthAfricanRand.format(round(promoInvoicePrice, 2))}</strong>
                    </Typography>
                )}
                {customerCategory !== CustomerCategory.Retail && (
                    <Typography variant="caption" color="textSecondary" sx={{ fontSize: '0.75rem' }}>
                        Ex. VAT
                    </Typography>
                )}
            </CardContent>

            <Box sx={{ p: 2 }}>
                {availability !== ProductAvailability.No_Stock &&
                    <Button
                        size="small"
                        variant="contained"
                        color="error"
                        fullWidth
                        onClick={handleAddToCartClick}
                    >
                        Add to cart
                    </Button>
                }
            </Box>

            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', md: 400 },
                    maxHeight: '80vh',
                    bgcolor: 'background.paper',
                    borderRadius: '8px',
                    boxShadow: 24,
                    p: 4,
                    overflowY: 'auto',
                }}>
                    <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
                        <CloseIcon />
                    </IconButton>
                    {modalContent}
                </Box>
            </Modal>
        </Card>
    );
};

export default GridItem;


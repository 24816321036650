import React, { useEffect } from "react";
import PageHeader from "../PageHeader";
import useSystemHook from "../../hooks/SystemHook";
import { ScreenSize } from "../../utils/constants";
import { Avatar, Box, Button, Container, Paper, Typography } from "@mui/material";

const PaymentCreditCardOnCollection = ({ paymentOrderInfo, onSubmit }) => {
	const { screenSize } = useSystemHook();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<PageHeader title="Payment" />
			<Box
				sx={{
					minHeight: screenSize === ScreenSize.Desktop ? "100vh" : "50vh",
					backgroundColor: "#f7f7f7",
					padding: screenSize === ScreenSize.Desktop ? "2rem" : "1rem"
				}}
			>
				<Container maxWidth={screenSize === ScreenSize.Desktop ? "md" : "sm"}>
					<Paper elevation={3} sx={{ padding: screenSize === ScreenSize.Desktop ? "2rem" : "1.5rem", textAlign: "center" }}>
						<Avatar
							src="https://argusweb.azureedge.net/argus-web/payment/creditcard.svg"
							alt="Credit Card"
							sx={{
								width: screenSize === ScreenSize.Desktop ? 100 : 80,
								height: screenSize === ScreenSize.Desktop ? 100 : 80,
								margin: "0 auto",
								mb: screenSize === ScreenSize.Desktop ? "1.5rem" : "1rem"
							}}
						/>

						{!!paymentOrderInfo && (
							<Box>
								<Typography variant="body2" fontWeight="bold" sx={{ mb: screenSize === ScreenSize.Desktop ? "1.5rem" : "1rem" }}>
									Credit Card Payment
								</Typography>
								<Typography variant="caption" color="textSecondary" sx={{ mb: screenSize === ScreenSize.Desktop ? "2rem" : "1.5rem" }}>
									Please click the "Pay" button below to proceed with your payment.
								</Typography>

								<Button
									variant="contained"
									color="error"
									size="small"
									onClick={onSubmit}
									sx={{
										width: screenSize === ScreenSize.Desktop ? "50%" : "100%",
										height: screenSize === ScreenSize.Desktop ? "48px" : "40px",
										fontSize: screenSize === ScreenSize.Desktop ? "1rem" : "0.875rem"
									}}
								>
									Pay
								</Button>
							</Box>
						)}
					</Paper>
				</Container>
			</Box>
		</>
	);
};

export default PaymentCreditCardOnCollection;

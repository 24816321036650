import React from "react";
import { Box, Grid, Paper, Typography, IconButton, Link } from "@mui/material";
import PageHeader from "../PageHeader";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import useSystemHook from "../../hooks/SystemHook";
import { ScreenSize } from "../../utils/constants";

const ContactUs = () => {
	const { screenSize } = useSystemHook();
	const isMobile = screenSize === ScreenSize.Mobile;

	const contactInfo = [
		{
			label: "Phone",
			icon: <PhoneIcon fontSize="small" />,
			link: "tel:0117475200",
			text: "(+27)11 747 5200",
		},
		{
			label: "WhatsApp",
			icon: <WhatsAppIcon fontSize="small" />,
			link: "https://wa.link/ov1mre",
			text: "083 645 5120",
		},
		{
			label: "Email",
			icon: <EmailIcon fontSize="small" />,
			link: "mailto:info@argusmotoring.co.za",
			text: "info@argusmotoring.co.za",
		},
	];

	const openingHours = (
		<Box sx={{ mb: 2 }}>
			<Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
				Opening Hours
			</Typography>
			<Typography variant="body2">
				Monday to Thursday: 8:00 AM - 4.30 PM
				<br />
				Friday: 8:00 AM - 1.30 PM
				<br />
				Saturday & Sunday: Closed
			</Typography>
		</Box>
	);

	const addressSection = (
		<Box sx={{ mb: 2 }}>
			<Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
				Address
			</Typography>
			<Link
				href="https://goo.gl/maps/RbgTFmVshrD2WJYu8"
				target="_blank"
				rel="noopener"
				variant="body2"
				sx={{ textDecoration: "none", color: "primary.main" }}
			>
				28 Great North Road, Brentwood Park, 1501, South Africa
			</Link>
		</Box>
	);

	const contactSection = (
		<Box sx={{ mb: 2 }}>
			<Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
				Contact Us
			</Typography>
			{contactInfo.map((info) => (
				<Link
					key={info.label}
					href={info.link}
					variant="body2"
					sx={{
						display: "flex",
						alignItems: "center",
						textDecoration: "none",
						color: "inherit",
						mb: 1,
					}}
				>
					<IconButton>{info.icon}</IconButton>
					{info.text}
				</Link>
			))}
		</Box>
	);

	const mapEmbed = (
		<Box sx={{ position: "relative", overflow: "hidden", pb: "56.25%", mb: 2 }}>
			<iframe
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.5795860208736!2d28.2970668!3d-26.1452474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9516007af0406f%3A0xccf786c2e19b2866!2sArgus%20Motor%20Company!5e0!3m2!1sen!2sza!4v1715939532944!5m2!1sen!2sza"
				width="100%"
				height="100%"
				style={{ border: 0, position: "absolute", top: 0, left: 0 }}
				allowFullScreen=""
				loading="lazy"
				title="Argus Location"
			></iframe>
		</Box>
	);

	return (
		<>
			<PageHeader title="Contact Us" />
			<Box sx={{ p: isMobile ? 2 : 4 }}>
				<Grid container spacing={isMobile ? 2 : 4}>
					{isMobile ? (
						<>
							<Grid item xs={12}>
								<Paper elevation={2} sx={{ mb: 3 }}>
									<img
										src="https://argusweb.azureedge.net/argus-web/contact-us/contact-us-storefront.jpg"
										alt="Our Store"
										style={{
											width: "100%",
											height: "auto",
											borderRadius: "4px",
										}}
									/>
								</Paper>
							</Grid>
							<Grid item xs={12}>{openingHours}</Grid>
							<Grid item xs={12}>{addressSection}</Grid>
							<Grid item xs={12}>{contactSection}</Grid>
							<Grid item xs={12}>{mapEmbed}</Grid>
						</>
					) : (
						<>
							<Grid item xs={12} md={6}>
								<Paper elevation={4}>
									<img
										src="https://argusweb.azureedge.net/argus-web/contact-us/contact-us-storefront.jpg"
										alt="Our Store"
										style={{
											width: "100%",
											height: "auto",
											borderRadius: "8px",
										}}
									/>
								</Paper>
							</Grid>
							<Grid item xs={12} md={6}>
								<Grid container spacing={4}>
									<Grid item xs={12}>{openingHours}</Grid>
									<Grid item xs={12}>{addressSection}</Grid>
									<Grid item xs={12}>{contactSection}</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>{mapEmbed}</Grid>
						</>
					)}
				</Grid>
			</Box>
		</>
	);
};

export default ContactUs;

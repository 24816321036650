import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { CartSummary } from "../CartSummary";
import useSystemHook from "../../hooks/SystemHook";
import { ScreenSize } from "../../utils/constants";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button, Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PageHeader from "../PageHeader";
import AddIcon from "@mui/icons-material/Add";

const CheckoutAddressUi = {
	firstLoad: -1,
	noAddressOptions: 0,
	addressDropdown: 1,
	addressForm: 2,
	addressChosen: 3,
	error: 4
};

const CheckoutBillingAddress = ({
	checkoutData,
	countries,
	provinces,
	userAddresses,
	onSubmit,
	gotoCreateAddressPage
}) => {
	const { screenSize } = useSystemHook();
	const [currentUiState, setCurrentUiState] = useState(CheckoutAddressUi.firstLoad);
	const [chosenAddressId, setChosenAddressId] = useState(checkoutData?.billingAddressId ?? null);

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	const renderAddressString = (address) => {
		const country = countries.find(x => x.countryId === address.countryId);
		const province = provinces.find(x => x.provinceId === address.provinceId);
		const addressArray = [
			address.streetAddress,
			address.suburb,
			address.city,
			address.postalCode,
			province?.name,
			country?.name
		];
		return addressArray.filter(Boolean).join(", ");
	};

	const onSaveAddressClick = async () => {
		if (!!chosenAddressId) {
			const checkoutDataClone = cloneDeep(checkoutData);
			checkoutDataClone.billingAddressId = chosenAddressId;

			await onSubmit({ checkoutData: checkoutDataClone });
		}
	};

	const onUseAddressClick = (addressId) => {
		setChosenAddressId(addressId);
	};

	const showAddressDropdown = () => {
		setCurrentUiState(CheckoutAddressUi.addressDropdown);
	};

	const showNoAddressOptions = () => {
		setCurrentUiState(CheckoutAddressUi.noAddressOptions);
	};

	useEffect(() => {
		scrollTop();
	}, []);

	useEffect(() => {
		if (userAddresses?.length > 0) {
			showAddressDropdown();
		} else {
			showNoAddressOptions();
		}
	}, [userAddresses]);

	useEffect(() => {
		if (checkoutData?.billingAddressId) {
			setChosenAddressId(checkoutData.billingAddressId);
		}
	}, [checkoutData]);

	return (
		<>
			<PageHeader title="Checkout" />
			{!!checkoutData && (
				<Box
					sx={{
						minHeight: '100vh',
						backgroundColor: '#f7f7f7',
						padding: { xs: 1, md: 2 },
					}}
				>
					<Grid container spacing={4}>
						<Grid item xs={12} md={9}>
							<Paper
								elevation={4}
								sx={{
									padding: { xs: '1rem', md: '2rem' },
									borderRadius: '8px',
									backgroundColor: '#ffffff',
									marginBottom: { xs: '1rem', md: 0 }
								}}
							>
								<Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
									<Typography variant="body2" color="textPrimary" sx={{ fontWeight: 'bold' }}>
										Billing Address
									</Typography>
									{screenSize === ScreenSize.Desktop ? (
										<Button
											variant="outlined"
											color="error"
											size="small"
											startIcon={<AddIcon fontSize="small" />}
											onClick={gotoCreateAddressPage}
											sx={{ textTransform: 'none' }}
										>
											Add Address
										</Button>
									) : (
										<IconButton color="error" onClick={gotoCreateAddressPage}>
											<AddCircleIcon fontSize="small" />
										</IconButton>
									)}
								</Box>

								{currentUiState === CheckoutAddressUi.noAddressOptions && (
									<Typography variant="body2" sx={{ color: '#777', padding: '1rem 0', textAlign: { xs: 'center', md: 'left' } }}>
										No addresses found.
									</Typography>
								)}

								{currentUiState === CheckoutAddressUi.addressDropdown && (
									<>
										<Divider sx={{ marginBottom: '1rem' }} />
										{userAddresses.map((address, index) => (
											<Box
												key={index}
												sx={{
													display: 'flex',
													flexDirection: { xs: 'column', md: 'row' },
													alignItems: 'center',
													padding: { xs: '1rem', md: '1rem 0' },
													borderBottom: { xs: 'none', md: '1px solid #e0e0e0' },
													marginBottom: { xs: '1rem', md: 0 }
												}}
											>
												<Box flexGrow={1}>
													<Typography variant="caption" component="p" sx={{ fontWeight: 'bold' }}>
														{`${address.firstName} ${address.lastName}`}
													</Typography>
													<Typography variant="caption" component="p" color="textSecondary">
														{renderAddressString(address)}
													</Typography>
												</Box>
												<Box sx={{ textAlign: { xs: 'center', md: 'right' }, mt: { xs: 1, md: 0 } }}>
													{chosenAddressId === address.addressId ? (
														<Button
															variant="contained"
															size="small"
															color="success"
															startIcon={<CheckIcon fontSize="small" />}
															disabled
														>
															Selected
														</Button>
													) : (
														<Button
															variant="contained"
															color="error"
															size="small"
															onClick={() => onUseAddressClick(address.addressId)}
															sx={{ textTransform: 'none' }}
														>
															Use
														</Button>
													)}
												</Box>
											</Box>
										))}
									</>
								)}
							</Paper>
						</Grid>

						<Grid item xs={12} md={3}>
							<CartSummary
								vat={checkoutData.vatPercentage}
								cartNettTotal={checkoutData.subtotal}
								settlementAmount={checkoutData.settlementAmount}
								vatAmount={checkoutData.vatAmount}
								total={checkoutData.total}
								onButtonClick={onSaveAddressClick}
								buttonText="Continue"
								buttonDisabled={!chosenAddressId}
								sx={{ marginBottom: { xs: '2rem', md: 0 } }}
							/>
						</Grid>
					</Grid>
				</Box>
			)}
		</>
	);
};

export default CheckoutBillingAddress;

import React, { useEffect, useState } from "react";
import { DeliveryMethod, LogisticsMethod, ScreenSize } from "../../utils/constants";
import { cloneDeep } from "lodash";
import { CartSummary } from "../CartSummary";
import useSystemHook from "../../hooks/SystemHook";
import { Box, Button, Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import StoreIcon from "@mui/icons-material/Store";
import PageHeader from "../PageHeader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CheckoutDeliveryMethod = ({
	checkoutData,
	chosenLogisticsMethod,
	setChosenLogisticsMethod,
	onSubmit
}) => {
	const { screenSize } = useSystemHook();
	const [showError, setShowError] = useState(false);

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		scrollTop();
	}, []);

	const handleDeliveryMethodClick = (deliveryMethod) => {
		const logisticsMethod = deliveryMethod === DeliveryMethod.Ship
			? LogisticsMethod.Ship
			: LogisticsMethod.Collection;

		setShowError(false);
		setChosenLogisticsMethod(logisticsMethod);
	};

	const onSaveClick = async () => {
		if (chosenLogisticsMethod) {
			const checkoutDataClone = cloneDeep(checkoutData);
			checkoutDataClone.logisticsMethod = chosenLogisticsMethod;
			checkoutDataClone.shippingAddressId = null;
			checkoutDataClone.collectionAddressId = null;

			await onSubmit({ checkoutData: checkoutDataClone });
		} else {
			setShowError(true);
		}
	};

	const showAsCurrent = chosenLogisticsMethod || checkoutData?.logisticsMethod;

	return (
		<>
			<PageHeader title="Checkout" />
			{!!checkoutData && (
				<Box sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: { xs: 1, md: 2 } }}>
					<Grid container spacing={4}>
						{/* Delivery Method Section */}
						<Grid item xs={12} md={9}>
							{showError && (
								<Paper
									elevation={3}
									sx={{
										padding: '1rem',
										marginBottom: '1rem',
										backgroundColor: '#ffe6e6',
										color: '#d32f2f',
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center'
									}}
								>
									<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
										<ErrorOutlineIcon fontSize="small" /> Something went wrong
									</Typography>
									<Typography variant="caption">Please choose a delivery method</Typography>
								</Paper>
							)}

							<Paper elevation={3} sx={{ padding: '1rem', marginBottom: '1rem' }}>
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
										<LocalShippingIcon fontSize="small" /> Delivery
									</Typography>
									{showAsCurrent === LogisticsMethod.Ship ? (
										screenSize === ScreenSize.Mobile ? (
											<IconButton color="success" disabled>
												<CheckCircleIcon fontSize="small" />
											</IconButton>
										) : (
											<Button variant="contained" color="success" size="small" startIcon={<CheckIcon />} disabled>
												Selected
											</Button>
										)
									) : (
										<Button
											variant="outlined"
											color="error"
											size="small"
											onClick={() => handleDeliveryMethodClick(DeliveryMethod.Ship)}
											sx={{ textTransform: 'none' }}
										>
											Choose
										</Button>
									)}
								</Box>
								<Divider sx={{ my: 2 }} />
								<Typography variant="caption" component="p">Orders received Monday to Thursday before 9am will be dispatched the same day.</Typography>
								<Typography variant="caption" component="p" sx={{ mt: 0.5 }}>Orders received after 9am on Friday to Sunday will be dispatched on the following Monday.</Typography>
								<Typography variant="caption" component="p" sx={{ mt: 0.5 }}>Delivery typically takes between 2 and 3 working days to all main centers.</Typography>
								<Typography variant="caption" component="p" sx={{ mt: 0.5 }}>Smaller cities and rural areas may take 5 to 7 working days.</Typography>
							</Paper>

							<Paper elevation={3} sx={{ padding: '1rem' }}>
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
										<StoreIcon fontSize="small" /> Collection
									</Typography>
									{showAsCurrent === LogisticsMethod.Collection ? (
										screenSize === ScreenSize.Mobile ? (
											<IconButton color="success" disabled>
												<CheckCircleIcon fontSize="small" />
											</IconButton>
										) : (
											<Button variant="contained" color="success" size="small" startIcon={<CheckIcon />} disabled>
												Selected
											</Button>
										)
									) : (
										<Button
											variant="outlined"
											color="error"
											size="small"
											onClick={() => handleDeliveryMethodClick(DeliveryMethod.Collection)}
											sx={{ textTransform: 'none' }}
										>
											Choose
										</Button>
									)}
								</Box>
								<Divider sx={{ my: 2 }} />
								<Typography variant="caption" component="p">Orders are generally ready within 60 minutes from confirmation of payment.</Typography>
								<Box sx={{ mt: 2 }}>
									<Typography variant="caption" component="p"><b>Collection address:</b></Typography>
									<Typography variant="caption" component="p">28 Great North Road, Brentwood Park, Benoni, 1501</Typography>
								</Box>
								<Box sx={{ mt: 2 }}>
									<Typography variant="caption" component="p"><b>Collection times:</b></Typography>
									<Typography variant="caption" component="p">Mon-Thu 8:00am-4:30pm</Typography>
									<Typography variant="caption" component="p">Fri: 8:00am-1:30pm</Typography>
								</Box>
							</Paper>
						</Grid>

						{/* Cart Summary Section */}
						<Grid item xs={12} md={3}>
							<CartSummary
								vat={checkoutData.vatPercentage}
								cartNettTotal={checkoutData.subtotal}
								settlementAmount={checkoutData.settlementAmount}
								vatAmount={checkoutData.vatAmount}
								total={checkoutData.total}
								buttonText="Continue"
								buttonDisabled={!chosenLogisticsMethod}
								onButtonClick={onSaveClick}
								sx={{ marginBottom: { xs: '2rem', md: 0 } }}
							/>
						</Grid>
					</Grid>
				</Box>
			)}
		</>
	);
};

export default CheckoutDeliveryMethod;

import React, { useEffect, useState } from "react";
import { PaymentMethod, PaymentType, ScreenSize } from "../../utils/constants";
import useSystemHook from "../../hooks/SystemHook";
import {
	Avatar, Box,
	Button,
	List,
	ListItem,
	Paper,
	Typography
} from "@mui/material";
import PageHeader from "../PageHeader";

const Payment = ({
	accountCreditPaymentOrderInfo,
	fullTradeAccount,
	gotoPaymentPayfastPage,
	gotoPaymentAccountCredit,
	gotoPaymentEftPage,
	gotoPaymentCreditCardOnCollectionPage,
	paymentMethods
}) => {
	const { screenSize } = useSystemHook();
	const [recommendedPaymentMethod, setRecommendedPaymentMethod] = useState(PaymentMethod.PayFast);

	useEffect(() => window.scrollTo(0, 0), []);

	useEffect(() => {
		if (fullTradeAccount?.availableCredit >= accountCreditPaymentOrderInfo?.amount) {
			setRecommendedPaymentMethod(PaymentMethod.AccountCredit);
		}
	}, [fullTradeAccount, accountCreditPaymentOrderInfo]);

	const paymentOptions = [
		{
			type: PaymentType.PayFast,
			label: "Pay via Payfast",
			image: "https://argusweb.azureedge.net/argus-web/payment/payfast.svg",
			action: gotoPaymentPayfastPage,
			method: PaymentMethod.PayFast
		},
		{
			type: PaymentType.AccountCredit,
			label: "Pay with Account Credit",
			image: "https://argusweb.azureedge.net/argus-web/logo.svg",
			action: gotoPaymentAccountCredit,
			method: PaymentMethod.AccountCredit,
			condition: !!fullTradeAccount
		},
		{
			type: PaymentType.EFT,
			label: "Pay via EFT",
			image: "https://argusweb.azureedge.net/argus-web/payment/eft.svg",
			action: gotoPaymentEftPage,
			method: PaymentMethod.EFT
		},
		{
			type: PaymentType.CreditCardOnCollection,
			label: "Pay via Credit Card on Collection",
			image: "https://argusweb.azureedge.net/argus-web/payment/creditcard.svg",
			action: gotoPaymentCreditCardOnCollectionPage,
			method: PaymentMethod.CardOnCollection
		}
	];

	const renderPaymentOption = (option) => (
		<ListItem key={option.type} sx={{ display: 'flex', alignItems: 'center', padding: '12px' }}>
			<Avatar
				variant="square"
				alt={option.label}
				src={option.image}
				sx={{ width: screenSize === ScreenSize.Desktop ? 150 : 60, height: screenSize === ScreenSize.Desktop ? 50 : 40, mr: 2 }}
			/>
			<Box sx={{ flex: 1 }}>
				<Typography variant="body2" sx={{ fontWeight: 'bold' }}>{option.label}</Typography>
				{recommendedPaymentMethod === option.method && (
					<Typography variant="caption" color="error">Recommended</Typography>
				)}
			</Box>
			<Button
				variant="contained"
				color="error"
				size="small"
				onClick={option.action}
			>
				Pay
			</Button>
		</ListItem>
	);

	return (
		<>
			<PageHeader title="Payment" />
			<Box sx={{ minHeight: screenSize === ScreenSize.Desktop ? '100vh' : '50vh', backgroundColor: '#f7f7f7', padding: screenSize === ScreenSize.Desktop ? '2rem' : '16px' }}>
				<Typography variant="body2" sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}>Choose your payment method</Typography>
				<Paper elevation={3} sx={{ padding: 2, mb: 2 }}>
					{!!paymentMethods &&
						<List>
							{paymentOptions
								.filter(option => paymentMethods.includes(option.type) && (option.condition === undefined || option.condition))
								.map(renderPaymentOption)}
						</List>
					}
				</Paper>
			</Box>
		</>
	);
};

export default Payment;

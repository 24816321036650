import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import Image from "../Image";
import PageHeader from "../PageHeader";

const PaymentPayfast = ({ paymentOrderInfo }) => {
	const [payfast, setPayfast] = useState(null);

	useEffect(() => {
		if (paymentOrderInfo?.payfast) {
			setPayfast(paymentOrderInfo.payfast);
		}
		window.scrollTo(0, 0);
	}, [paymentOrderInfo?.payfast]);

	const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;
	const apiBaseUrl = process.env.REACT_APP_ORDER_API_URL;
	const payfastBaseUrl = process.env.REACT_APP_PAYFAST_URL;

	return (
		<Box sx={{ minHeight: "100vh", padding: "2rem", backgroundColor: "#f7f7f7" }}>
			<PageHeader title="Payment" />

			<Grid container justifyContent="center">
				<Grid item xs={12} md={8} lg={6}>
					<Paper elevation={3} sx={{ padding: "2rem", textAlign: "center" }}>
						<Box sx={{ mb: 3 }}>
							<Image
								src="https://argusweb.azureedge.net/argus-web/payment/payfast.svg"
								alt="Payfast Logo"
								sx={{ maxWidth: "150px", margin: "0 auto" }}
							/>
						</Box>

						{payfast && (
							<Box sx={{ mb: 4 }}>
								<Typography variant="body2" sx={{ mb: 2 }}>
									You will be securely redirected to Payfast to complete this transaction.
								</Typography>

								<form action={`https://${payfastBaseUrl}/eng/process`} method="post">
									<input type="hidden" name="merchant_id" value={paymentOrderInfo?.payfast?.merchantId} />
									<input type="hidden" name="m_payment_id" value={paymentOrderInfo?.payfast?.merchantPaymentId} />
									<input type="hidden" name="merchant_key" value={paymentOrderInfo?.payfast?.merchantKey} />
									<input type="hidden" name="name_first" value={paymentOrderInfo?.payfast?.firstName} />
									<input type="hidden" name="name_last" value={paymentOrderInfo?.payfast?.lastName} />
									<input type="hidden" name="email_address" value={paymentOrderInfo?.payfast?.email} />
									<input type="hidden" name="custom_str1" value={paymentOrderInfo?.payfast?.orderId} />
									<input type="hidden" name="amount" value={paymentOrderInfo.amount} />
									<input type="hidden" name="item_name" value={paymentOrderInfo?.payfast?.orderNumber} />
									<input type="hidden" name="return_url" value={`${baseUrl}/order-confirmation/${paymentOrderInfo?.payfast?.orderId}`} />
									<input type="hidden" name="cancel_url" value={`${baseUrl}/payment`} />
									<input type="hidden" name="notify_url" value={`${apiBaseUrl}/api/payfast/ipn`} />

									<Button
										variant="contained"
										size="small"
										color="error"
										type="submit"
										sx={{ mt: 2 }}
									>
										Pay now
									</Button>
								</form>
							</Box>
						)}
					</Paper>
				</Grid>
			</Grid>
		</Box>
	);
};

export default PaymentPayfast;

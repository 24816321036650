import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { CartSummary } from "../CartSummary";
import useSystemHook from "../../hooks/SystemHook";
import { ScreenSize } from "../../utils/constants";
import {
	Box,
	Button,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckIcon from "@mui/icons-material/Check";
import PageHeader from "../PageHeader";
import AddIcon from "@mui/icons-material/Add";

const CheckoutAddressUi = {
	firstLoad: -1,
	noAddressOptions: 0,
	addressDropdown: 1,
	addressChosen: 3,
	error: 4
};

const CheckoutShippingAddress = ({
	checkoutData,
	countries,
	provinces,
	userAddresses,
	onSubmit,
	gotoCreateAddressPage
}) => {
	const { screenSize } = useSystemHook();
	const [currentUiState, setCurrentUiState] = useState(CheckoutAddressUi.firstLoad);
	const [chosenAddressId, setChosenAddressId] = useState(checkoutData?.shippingAddressId ?? null);

	const scrollTop = () => window.scrollTo(0, 0);

	const onSaveAddressClick = async () => {
		if (chosenAddressId) {
			const checkoutDataClone = cloneDeep(checkoutData);
			checkoutDataClone.shippingAddressId = chosenAddressId;
			checkoutDataClone.collectionAddressId = null;
			await onSubmit({ checkoutData: checkoutDataClone });
		}
	};

	const onUseAddressClick = (addressId) => setChosenAddressId(addressId);

	const showAddressDropdown = () => setCurrentUiState(CheckoutAddressUi.addressDropdown);
	const showNoAddressOptions = () => setCurrentUiState(CheckoutAddressUi.noAddressOptions);

	const renderAddressString = (address) => {
		const country = countries.find((x) => x.countryId === address.countryId);
		const province = provinces.find((x) => x.provinceId === address.provinceId);
		const addressArray = [
			address.streetAddress,
			address.suburb,
			address.city,
			address.postalCode,
			province?.name,
			country?.name
		];
		return addressArray.filter(Boolean).join(", ");
	};

	useEffect(() => {
		scrollTop();
		if (userAddresses?.length > 0) showAddressDropdown();
		else showNoAddressOptions();
	}, [userAddresses]);

	useEffect(() => {
		if (checkoutData?.shippingAddressId) setChosenAddressId(checkoutData.shippingAddressId);
	}, [checkoutData]);

	return (
		<>
			<PageHeader title="Checkout" />
			<Box sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: { xs: 1, md: 2 } }}>
				{!!checkoutData && (
					<Grid container spacing={2}>
						<Grid item xs={12} md={9}>
							<Paper elevation={3} sx={{ padding: { xs: '1rem', md: '1.5rem' } }}>
								<Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
									<Typography variant="body2" color="textPrimary" sx={{ fontWeight: 'bold' }}>
										Shipping Address
									</Typography>
									{screenSize === ScreenSize.Desktop ? (
										<Button
											size="small"
											variant="outlined"
											color="error"
											startIcon={<AddIcon fontSize="small" />}
											onClick={gotoCreateAddressPage}
										>
											Add Address
										</Button>
									) : (
										<IconButton color="error" onClick={gotoCreateAddressPage}>
											<AddCircleIcon fontSize="small" />
										</IconButton>
									)}
								</Box>
								{currentUiState === CheckoutAddressUi.noAddressOptions && (
									<Typography variant="body2" sx={{ color: 'gray', textAlign: 'center', my: 2 }}>
										No addresses found.
									</Typography>
								)}
								{currentUiState === CheckoutAddressUi.addressDropdown && (
									<List sx={{ width: '100%', backgroundColor: '#fafafa', borderRadius: '8px' }}>
										{userAddresses.map((address, index) => (
											<React.Fragment key={index}>
												<ListItem sx={{ paddingY: '0.5rem', alignItems: 'flex-start' }}>
													<ListItemText
														primary={`${address.firstName} ${address.lastName}`}
														secondary={renderAddressString(address)}
														sx={{ flexGrow: 1 }}
													/>
													{chosenAddressId === address.addressId ? (
														<Button
															variant="contained"
															color="success"
															size="small"
															startIcon={<CheckIcon fontSize="small" />}
															disabled
															fullWidth={screenSize === ScreenSize.Mobile}
														>
															Selected
														</Button>
													) : (
														<Button
															variant="contained"
															color="error"
															size="small"
															onClick={() => onUseAddressClick(address.addressId)}
															fullWidth={screenSize === ScreenSize.Mobile}
															sx={{ textTransform: 'none' }}
														>
															Use
														</Button>
													)}
												</ListItem>
												{index < userAddresses.length - 1 && <Divider />}
											</React.Fragment>
										))}
									</List>
								)}
							</Paper>
						</Grid>
						<Grid item xs={12} md={3}>
							<CartSummary
								vat={checkoutData.vatPercentage}
								cartNettTotal={checkoutData.subtotal}
								settlementAmount={checkoutData.settlementAmount}
								vatAmount={checkoutData.vatAmount}
								logisticsFee={checkoutData.logisticsFee}
								total={checkoutData.total}
								buttonText="Continue"
								buttonDisabled={!chosenAddressId}
								onButtonClick={onSaveAddressClick}
								sx={{ marginBottom: '2rem' }}
							/>
						</Grid>
					</Grid>
				)}
			</Box>
		</>
	);
};

export default CheckoutShippingAddress;

import { Box, Button, IconButton, Modal } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { AnnouncementType, ScreenSize } from "../../utils/constants";
import useSystemHook from "../../hooks/SystemHook";

const modalStyles = {
	desktop: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		boxShadow: 24,
		bgcolor: "background.paper",
		padding: 2,
		width: "60vw",
		height: "80vh",
		overflowY: "auto",
		borderRadius: 2,
	},
	mobile: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		boxShadow: 24,
		bgcolor: "background.paper",
		padding: 2,
		width: "90vw",
		height: "auto",
		maxHeight: "90vh",
		overflowY: "auto",
		borderRadius: 2,
	},
};

const imageStyles = {
	desktop: {
		maxWidth: "90%",
		height: "auto",
		marginBottom: "12px",
	},
	mobile: {
		maxWidth: "100%",
		height: "auto",
		marginBottom: "16px",
	},
};

const buttonContainerStyles = {
	desktop: {
		display: "flex",
		justifyContent: "center",
		gap: 2,
		marginTop: "24px",
	},
	mobile: {
		display: "flex",
		justifyContent: "center",
		gap: 1,
		marginTop: "16px",
		flexDirection: "column",
	},
};

const AnnouncementModal = ({
	open,
	setClose,
	type,
	image,
	gotoLink,
	announcementId,
	markAnnouncementAsSeen,
	markAnnouncementAsAcknowledged,
}) => {
	const { screenSize } = useSystemHook();
	const isDesktop = screenSize === ScreenSize.Desktop;
	const styles = isDesktop ? "desktop" : "mobile";

	const closeModal = () => setClose();

	const handleAcknowledge = async () => {
		closeModal();
		await markAnnouncementAsAcknowledged(announcementId);
	};

	const handleSeen = async () => {
		closeModal();
		await markAnnouncementAsSeen(announcementId);
	};

	return (
		<Modal
			open={open}
			onClose={closeModal}
			disableBackdropClick
			disableEscapeKeyDown
			aria-labelledby="announcement-modal-title"
			aria-describedby="announcement-modal-description"
		>
			<Box sx={modalStyles[styles]} className="modalContent">
				<IconButton
					onClick={handleSeen}
					sx={{
						position: "absolute",
						top: 8,
						right: 8,
						color: "text.secondary",
					}}
				>
					<CloseIcon fontSize={isDesktop ? "medium" : "small"} />
				</IconButton>

				<Box component="img" src={image} alt="Announcement" sx={imageStyles[styles]} />

				<Box sx={buttonContainerStyles[styles]}>
					{type === AnnouncementType.Acknowledgement && (
						<Button variant="contained" color="error" size="small" onClick={handleAcknowledge}>
							Acknowledge
						</Button>
					)}
					{type === AnnouncementType.Promotional && (
						<Button variant="contained" color="error" size="small" onClick={gotoLink}>
							View
						</Button>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default AnnouncementModal;
